.inline-social--block {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  .social {
    display: flex;

    li {
      width: 50px;
      text-align: center;

      a {
        font-size: 1.5rem;
        color: $orange;
        transition: all 0.3s;
        display: inline-block;

        &:hover {
          margin-top: -5px;
          margin-bottom: 5px;

          i {
            box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    position: relative;
    margin: 10px auto;
    text-align: center;
    display: block;

    .social {
      justify-content: center;
    }

    &.hide-mobile {
      display: none;
    }
  }

  // @media only screen and (min-width: $widescreen) {
  //   bottom: -30px;
  // }

  // @media only screen and (min-width: $fullhd) {
  //   bottom: -75px;
  //   right: 0;
  //   left: inherit;
  // }
}
