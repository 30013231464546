.contact-button {
  background-image: linear-gradient(to right, #f15f6c, #feb94b);
  color: #fff;
  padding: 14px 35px;
  font-size: 1.3rem;
  border: 0;
  border-radius: 10px;
  line-height: 2rem;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.5);
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &.extend {
    padding: 14px 50px;
  }

  @media only screen and (max-width: $tablet) {
    margin: 0 auto;
    display: inherit;
  }
}

.place-right {
  float: right;
}
