.modal-box--container {
  background: #fff;
  width: 100%;
  max-width: 55rem;
  padding: 30px 50px;
  border-radius: 15px;

  .modal-header {
    background: inherit;
    position: relative;
    min-height: 50px;

    .title {
      font-size: 2rem;
      font-weight: 700;
    }

    .close-btn {
      background: inherit;
      height: 50px;
      width: 50px;
      outline: 0;
      position: relative;
      vertical-align: top;
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s;

      &:after,
      &:before {
        background-color: #222;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
      }

      &:after {
        width: 2px;
        height: 80%;
      }

      &:before {
        height: 2px;
        width: 80%;
      }

      &:hover {
        background: #eee;
      }
    }
  }

  .name-label {
    font-weight: 700;
    margin-bottom: 5px;
    display: inline-block;
  }

  .input,
  .textarea {
    background: #f5f5f5;
    box-shadow: none;
    border: 0;
    font-size: 16px;
  }

  .input {
    padding: 1.5em 1.25em;
  }

  .send-btn[disabled] {
    background-color: white;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: 0.5;
  }

  %logError {
    background: #fb9c91;
    position: absolute;
    left: 0;
    bottom: -16px;
    font-size: 0.7rem;
    color: #fff;
    padding: 0 5px;
    letter-spacing: 0.5px;
  }

  .modal-body {
    .control>span {
      position: relative;
      display: inherit;

      input.input+.log-error {
        @extend %logError;
      }
    }

    .text-msg>span {
      position: relative;
      display: inherit;

      .textarea+.log-error {
        @extend %logError;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    border-radius: 10px;
    padding: 20px 30px;
  }
}
