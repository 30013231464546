.flat {
  height: 100vh;
  width: 100%;
}

.post-relative {
  position: relative;
}

.float-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.sbr-container {
  padding: 50px 0;

  @media only screen and (min-width: $fullhd) {
    padding: 100px 0;
  }

  @media only screen and (max-width: $tablet) {
    > .columns {
      margin: 0;

      .hero-section--box {
        grid-area: hero-section;
      }

      .slide-section {
        grid-area: slide-section;
      }
    }
  }
}
