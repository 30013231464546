.contact-section--block {
  position: absolute;
  bottom: 0;
  left: 0;

  li {
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    color: $default;

    i {
      width: 40px;
      font-size: 1.5rem;
      color: $orange;
    }
  }

  @media only screen and (max-width: $tablet) {
    position: relative;

    li {
      justify-content: center;

      i {
        text-align: center;
      }
    }
  }

  // @media only screen and (min-width: $widescreen) {
  //   bottom: -30px;
  // }

  // @media only screen and (min-width: $fullhd) {
  //   bottom: -75px;
  // }
}
