.float-cover {
  background-size: cover;
  position: absolute;
  width: 90%;
  height: calc(90vh - 100px);
  right: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: $tablet) {
    width: 200%;
  }

  // @media only screen and (min-width: $desktop) {
  //   left: 10%;
  // }

  // @media only screen and (min-width: $widescreen) {
  //   left: 20%;
  // }

  // @media only screen and (min-width: $fullhd) {
  //   left: 20%;
  // }
}

.feature-logo {
  width: 200px;
  margin: 0 auto 55px;
  margin-bottom: 30px;

  img {
    height: auto;
  }

  @media only screen and (max-width: $tablet) {
    width: 170px;
    display: block;
    margin-bottom: 10px;

    &.hide-desktop {
      display: block;
    }

    &.hide-mobile {
      display: none;
    }
  }

  @media only screen and (min-width: $fullhd) {
    width: 300px;
    margin-bottom: 55px;
  }
}

.sbr-content {
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 30px;

    @media only screen and (max-width: $tablet) {
      text-align: center;
      font-size: 1.5rem;
    }

    @media only screen and (min-width: $fullhd) {
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 35px;
    }
  }

  .content {
    margin-bottom: 50px;

    p {
      font-size: 1.1rem;
      margin-bottom: 10px;
      color: $default;
    }

    @media only screen and (max-width: $tablet) {
      p {
        text-align: center;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    @media only screen and (min-width: $widescreen) {
      p {
        font-size: 1.3rem;
      }
    }

    @media only screen and (min-width: $fullhd) {
      p {
        font-size: 1.5rem;
      }
    }
  }
}

.slide-section {
  position: relative;
}
