.slider-container {
  position: relative;

  .swiper-slide {
    position: relative;

    .slide-label {
      color: #fff;
      font-weight: 500;
      font-size: 1.3rem;
      position: absolute;
      max-width: 200px;
    }
  }

  .swiper-container {
    margin-top: -50px;
    margin-bottom: 50px;

    @media only screen and (max-width: $tablet) {
      margin: 0;
    }
  }

  .swiper-pagination {
    left: initial;
    right: 0;
    top: 50%;
    width: 50px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    transform: translateY(-50%);

    .swiper-pagination-bullet {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: $tablet) {
    // margin-top: 100px;

    .swiper-slide {
      .slide-label {
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        padding: 2px 10px;
        z-index: 2;
        background: rgba(34, 34, 34, 0.15);
        width: max-content;
        text-align: center;
        line-height: 22px;
        font-size: 1rem;
        display: inline-block;
        max-width: inherit;

        &.web-development {
          top: 25px;
        }

        &.web-design {
          top: 67px;
          left: 40%;
        }

        &.software-programming {
          top: 50px;
          left: 55%;
        }

        &.patent {
          top: 50px;
        }
      }
    }
  }

  @media only screen and (min-width: $desktop) {
    height: 40rem;
    height: calc(35rem + 50px);

    .swiper-container {
      margin-right: calc((100vw - 960px) / -2);
      margin-left: calc((100vw - 960px) / 2);

      // .swiper-pagination {
      //   margin-right: calc(((100vw - 960px) / -2) + 20px);
      // }
    }

    .swiper-slide .slide-label {
      &.web-development {
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.mobile {
        top: 20px;
      }

      &.web-design {
        top: 130px;
        left: 45%;
        transform: translateX(-50%);
      }

      &.software-programming {
        max-width: 100%;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.patent {
        max-width: 100%;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.seo {
        left: 80px;
        top: 80px;
      }
    }
  }

  @media only screen and (min-width: $widescreen) {
    height: calc(40rem + 50px);

    .swiper-container {
      margin-right: calc((100vw - 1152px) / -2);
      margin-left: calc((100vw - 1152px) / 2);

      // .swiper-pagination {
      //   margin-right: calc(((100vw - 1152px) / -2) + 20px);
      // }
    }

    .swiper-slide .slide-label {
      &.web-development {
        top: 70px;
      }

      &.web-design {
        top: 150px;
      }

      &.software-programming {
        top: 115px;
      }

      &.patent {
        top: 125px;
      }

      &.seo {
        left: 80px;
        top: 80px;
      }
    }
  }

  @media only screen and (min-width: $fullhd) {
    width: 100%;
    height: calc(45rem + 50px);

    .swiper-container {
      margin-right: calc((100vw - 1344px) / -2);
      margin-left: calc((100vw - 1344px) / 2);

      // .swiper-pagination {
      //   margin-right: calc(((100vw - 1344px) / -2) + 20px);
      // }
    }

    .swiper-slide .slide-label {
      &.web-development {
        top: 80px;
      }

      &.web-design {
        top: 170px;
      }

      &.seo {
        top: 100px;
        left: 100px;
      }
    }
  }
}
